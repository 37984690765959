@use '../breakpoints';

.App-header {
	color: black;
	height: 98vh;

	div.heading-background {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		opacity: 0.2;
		font-size: 18vw;
		z-index: -1;
		color: #9352ae;

		h1 {
			position: fixed;
			font-size: calc(15px + 0.9em);
			top: 30%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			text-align: center;

			@include breakpoints.media("<=700px"){
				top: 40%;
				font-size: calc(15px + 0.7em);
			}
		}
	}

	header {
		display: flex;
		justify-content: space-between;

		@include breakpoints.media(">=tablet") {
			display: inherit;
		}

		h1 {
			font-size: calc(20px + 2vw);
			font-weight: 400;
			margin: 3vh 0 0 0;
		}
	}

	.header-title {
		margin-top: 25vh;
		font-size: calc(15px + 2vw);
		text-align: left;
		white-space: pre-wrap;

		@include breakpoints.media(">=phone") {
			margin-left: 10vw;
		}

		@include breakpoints.media(">=tablet") {
			margin-top: 10vh;
		}

		button {
			background: black;
			padding: 1em 3em;
			border-radius: 2%;

			a {
				font-size: calc(10px + 3vw);
				font-family: 'Open Sans', sans-serif;
				color: white;
			}
		}
	}

	.scrollIndicator {
		display: block;
		position: absolute;
		cursor: pointer;
		font-size: calc(20px + 15vw);
		bottom: 15vh;
		text-align: center;
		left: calc(50vw - (20px + 15vw)/2);

		animation: bounce 2s;
		animation-delay: 1s;
		animation-iteration-count: infinite;

		@include breakpoints.media(">=tablet") {
			font-size: 8vw;
			left: 46vw;
			bottom: 2vh;
		}
	}

	@keyframes bounce {

		0%,
		25%,
		50%,
		75%,
		100% {
			transform: translateY(0);
		}

		40% {
			transform: translateY(-20px);
		}

		60% {
			transform: translateY(-12px);
		}
	}
}
