@use '../breakpoints';

.contact {
	margin-top: 8vh;
	padding-bottom: 3vh;
	text-align: center;
	justify-content: center;

	h1 {
		text-align: left;
	}

	.contact-content {

		h3 {
			font-size: calc(16px + 0.2vw);

			.amazing-color {
				color: #9352ae;
				opacity: 1;
			}
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			padding-left: 0;
			list-style-type: none;

			li {
				padding: 0.3em;
				margin: 0 1.5vw 1vh 1.5vw;
				border: 1px solid #00000038;
				border-radius: 10%;
				top: 0;
				transition: top ease 0.5s;

				&:hover {
					background-color: #9352ae22;
				}
			}
		}
	}
}

div.footer {
	padding: 0;
	margin: -0.1em auto;
	border-top: 1px solid black;
	font-size: calc(16px + 0.1vw);
	width: 50vw;
	height: 3em;
	left: calc(50% - 50vw + 0.1em);
	//background-color: pink;

	@include breakpoints.media("<=952px") {
		left: calc(50% - 27.5vw);
		width: 55vw;
	}

	@include breakpoints.media("<=700px") {
		font-size: calc(8px + 1vw);
		margin-bottom: 3em;
	}
}
