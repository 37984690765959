.work {
	.heading {
		text-align: left;
	}

	.work-content {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
		grid-gap: 1vw;
	}
}
