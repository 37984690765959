@use '../breakpoints';

.project {
	box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	@include breakpoints.media(">=700px"){
		height: 31em;
	}

	.projectImage {
		display: flex;
		flex-direction: column;
		width: inherit;
		height: 12em;

		a, img, svg {
			color: rgb(107, 107, 107);
			height: inherit;
			width: 100%;
			object-fit: contain;
		}
	}

	h4 a {
		padding: 0 0.7vw;

		@include breakpoints.media(">=tablet") {
			opacity: 0.5;
			&:hover {
				opacity: 1;
			}
		}
	}

	h1 {
		font-size: calc(12px + 0.85vw);
	}

	h2 {
		font-size: calc(10px + 0.7vw);
	}

	p {
		font-size: calc(8px + 0.65vw);
	}

	ul {
		padding-left: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		list-style-type: none;

		li {
			font-weight: 500;
			padding: 0 0.8em;
			margin: 0 0.5em 1em;
		}
	}

	li {
		font-size: calc(19px + 0.1em);
	}

	.links {
		margin-top: -1vw;
	}

	.techs li {
		border: 1px solid rgba(255, 166, 0, 0.644);
		border-radius: 10%;
		font-size: calc(10px + 0.5vw);
	}
}
