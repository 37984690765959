@use '../breakpoints';

@font-face {
	font-family : 'Hasklug';
	src: url('../data/Hasklug.otf') format('opentype');
	font-display: swap;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Hasklug', 'Montserrat', 'monospace';

	div.app {
		padding: 0 5vw;
		font-size: calc(12px + 0.5vw);
		text-align: center;

		a {
			text-decoration: none;
			color: rgb(98, 91, 199);
		}
	}

	button {
		border: none;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		cursor: pointer;
		transition: opacity 0.2s ease-out;
		-webkit-transition: opacity 0.2s ease-out;
		-moz-transition: opacity 0.2s ease-out;
		-ms-transition: opacity 0.2s ease-out;
		-o-transition: opacity 0.2s ease-out;

		&:hover {
			opacity: 0.65;
		}
	}
}

.show-desktop {
	display: none;
}

// Empty per default
//.show-mobile {}

@include breakpoints.media('>=tablet') {

	.show-mobile {
		display: none;
	}

	.show-desktop {
		display: inherit;
	}
}
